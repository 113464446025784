$transition-time: 0.6s;
.timeline {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    &>li {
        // margin-bottom: 60px;
        overflow: hidden;
        margin: 0;
        position: relative;
        transition: color $transition-time;
        h3 {
            transition: color $transition-time;
        }
    }
    &>li:hover,
    &>li:focus {
        color: $color-hover;
        h3 {
            color: $color-hover;
        }
        .dot {
            background: $color-hover;
            animation: pulse 1.5s infinite;
            box-shadow: 0 0 0 0 rgba($color-hover, .5);
        }
    }
    .timeline-date {
        width: 35%;
        float: left;
        margin-top: 1.3em;
        text-align: right;
        padding-right: 2em;
    }
    .timeline-content {
        max-width: 40%;
        float: left;
        border-left: 3px $bar-background solid;
        padding-left: 2em;
    }
    .dot {
        width: 12px;
        height: 12px;
        background: $alt-color;
        position: absolute;
        left: calc(35% + 1.7em);
        top: 1.5em;
        border-radius: 100%;
    }
}

@keyframes pulse {
    0% {
        transform: scale(.9);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba($color-hover, 0);
    }
    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 rgba($color-hover, 0);
    }
}