.badge {
    display: inline-block;
    background-color: $alt-color;
    font-size: 0.8em;
    color: $font-color;
    margin: 0 5px 10px 0;
    padding: 1px 10px;
    border-radius: .75rem;
    transition: background-color 300ms;

    &:hover {
        background-color: $color-hover;
    }
}