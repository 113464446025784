@import "https://fonts.googleapis.com/css?family=PT+Sans";
@import "https://fonts.googleapis.com/css?family=Roboto";
@font-face {
  font-family: fontello;
  src: url("fontello.d97ffa4f.eot");
  src: url("fontello.d97ffa4f.eot#iefix") format("embedded-opentype"), url("fontello.6229976c.woff2") format("woff2"), url("fontello.400b85a2.woff") format("woff"), url("fontello.25610e48.ttf") format("truetype"), url("fontello.0edbc68c.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: none;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  margin-left: .2em;
  margin-right: .2em;
  font-family: fontello;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.icon-cloud:before {
  content: "";
}

.icon-cloud-flash:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.badge {
  color: #2b303d;
  background-color: #eee;
  border-radius: .75rem;
  margin: 0 5px 10px 0;
  padding: 1px 10px;
  font-size: .8em;
  transition: background-color .3s;
  display: inline-block;
}

.badge:hover {
  background-color: #f5df8e;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.timeline {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.timeline > li {
  margin: 0;
  transition: color .6s;
  position: relative;
  overflow: hidden;
}

.timeline > li h3 {
  transition: color .6s;
}

.timeline > li:hover, .timeline > li:focus, .timeline > li:hover h3, .timeline > li:focus h3 {
  color: #f5df8e;
}

.timeline > li:hover .dot, .timeline > li:focus .dot {
  background: #f5df8e;
  animation: 1.5s infinite pulse;
  box-shadow: 0 0 #f5df8e80;
}

.timeline .timeline-date {
  float: left;
  text-align: right;
  width: 35%;
  margin-top: 1.3em;
  padding-right: 2em;
}

.timeline .timeline-content {
  float: left;
  border-left: 3px solid #232833;
  max-width: 40%;
  padding-left: 2em;
}

.timeline .dot {
  background: #eee;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 1.5em;
  left: calc(35% + 1.7em);
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px #f5df8e00;
  }

  100% {
    transform: scale(.9);
    box-shadow: 0 0 #f5df8e00;
  }
}

section#about {
  text-align: center;
  background-color: #eee;
  border-bottom: 3px solid #232833;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 1em;
  display: flex;
}

section#about span, section#about p {
  opacity: .8;
}

section#about p {
  line-height: 1.25;
}

section#about header {
  flex-flow: column;
  align-items: center;
  display: flex;
}

section#about header .profile-img {
  color: #fff;
  background: #2b303d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 5.5em;
  height: 5.5em;
  margin: 0 0 1.2em;
  display: flex;
}

section#about header .profile-img span {
  font-size: 2em;
}

section#about header h2 {
  margin: 1.3em 0 0;
  line-height: .8;
}

section#about header h1 {
  color: #2b303d;
  line-height: .2;
}

section#about header h2, section#about header h3, section#about header h4, section#about header h5, section#about header h6 {
  color: #2b303d;
}

section#about #contact header {
  margin-bottom: 1em;
  padding: 0;
}

section#about #contact header h2 {
  margin-top: .5em;
}

section#about #contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

section#about #contact ul li a {
  transition: opacity .6s;
}

section#about #contact ul li a:hover {
  opacity: .6;
}

section#about #contact svg {
  width: 2.3em;
}

#skills {
  position: relative;
}

#skills #dev-skills ul {
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
}

#skills #dev-skills ul, #skills #dev-skills li {
  margin: 0 .7em 0 0;
  padding: .3em 0;
}

#skills #dev-skills li {
  flex-flow: row;
  align-items: center;
  display: flex;
}

#skills #dev-skills li .icon svg {
  fill: #eee;
  width: 1.9em;
  transition: fill .6s, width .6s;
}

#skills #dev-skills li:hover .icon svg, #skills #dev-skills li:focus .icon svg {
  fill: #f5df8e;
}

article#experiences .content {
  flex-flow: column;
  align-items: center;
  display: flex;
}

article#experiences .content > div {
  width: 100%;
}

article#formal-education .content {
  flex-flow: column;
  align-items: center;
  display: flex;
}

article#formal-education .content > div {
  width: 100%;
}

body {
  color: #2b303d;
  background-color: #2b303d;
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: Sunflower, sans-serif;
  font-size: 1em;
  animation: 1.5s ease-out fadeIn;
}

main {
  color: #fff;
}

main section {
  padding: 1em;
}

footer {
  color: #666;
  background-color: #eee;
  border-top: 1px solid #eee;
  flex-flow: row;
  place-content: center space-between;
  align-items: center;
  padding: .5em;
  font-family: Roboto, sans-serif;
  font-size: .7em;
  display: flex;
  box-shadow: 0 1px 9px 1px #23283380;
}

footer .online {
  color: #3d9e6e;
}

footer .offline {
  color: #f35757;
}

h1, h2, h3, h4, h5, h6 {
  color: #ececece6;
}

@media only screen and (width >= 850px) {
  section#about {
    grid-area: about;
    height: calc(100% - 1em);
  }

  main {
    grid-area: page-content;
  }

  footer {
    grid-area: footer;
  }

  body {
    grid-template: "about page-content"
                   "footer footer" 30px
                   / 30% auto;
    min-height: 100vh;
    display: grid;
  }

  main .main-wrapper {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "skills skills"
                         "experience education";
    display: grid;
  }

  main .main-wrapper section#skills {
    border: none;
    grid-area: skills;
  }

  main .main-wrapper section#education {
    grid-area: education;
  }

  main .main-wrapper section#experience {
    grid-area: experience;
  }
}

/*# sourceMappingURL=index.f44fe894.css.map */
