@import url('https://fonts.googleapis.com/css?family=PT+Sans');
@import url('https://fonts.googleapis.com/css?family=Roboto');

// Main
$primary-font: 'Sunflower',
sans-serif;
$secondary-font: 'Roboto',
sans-serif;
$background-color: rgb(43, 48, 61);
$font-color: rgb(43, 48, 61);
$secondary-font-color: rgb(255, 255, 255);
$titles-color: rgba(236, 236, 236, .9);
$error-color: rgb(243, 87, 87);
$success-color: rgb(61, 158, 110);
// bars
$bar-background: rgb(35, 40, 51);
$alt-color:#eee; //rgb(40, 216, 204);
$color-hover: rgb(245, 223, 142);