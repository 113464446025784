@import './_fontello';
@import './_variables.scss';
@import './badges';
@import './_animations.scss';
@import './_timeline.scss';
@import './_mixins.scss';
@import './_header.scss';
@import './_skills.scss';
@import './_experience.scss';
@import './_education.scss';

body {
    padding: 0;
    margin: 0;
    background-color: $background-color;
    color: $font-color;
    font-family: $primary-font;
    font-size: 1em;
    min-width: 320px;
    animation: 1.5s ease-out 0s 1 fadeIn;
}

main {
    color: $secondary-font-color;

    section {
        padding: 1em;
    }
}

footer {
    font-family: $secondary-font;
    box-shadow: 0px 1px 9px 1px transparentize($bar-background, 0.5);
    border-top: 1px solid $alt-color; // color: $secondary-font-color;   
    background-color: $alt-color;
    font-size: 0.7em;
    color: rgb(102, 102, 102);
    padding: 0.5em;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    .online {
        color: $success-color;
    }

    .offline {
        color: $error-color;
    }


}

@for $i from 1 through 6 {
    h#{$i} {
        color: $titles-color;
    }
}

@media only screen and (min-width: 850px) {
    section#about {
        grid-area: about;
        height: calc(100% - 1em);
    }

    main {
        grid-area: page-content;
    }

    footer {
        grid-area: footer;
    }

    body {
        min-height: 100vh;
        display: grid;
        grid-template-columns: 30% auto;
        grid-template-rows: auto 30px;
        grid-template-areas: "about page-content"
            "footer footer";
    }



    main {
        .main-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-template-areas: "skills skills""experience education";

            section#skills {
                grid-area: skills;
                border: none;
            }

            section#education {
                grid-area: education;
            }

            section#experience {
                grid-area: experience;
            }
        }
    }
}