@mixin center-as-column {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

@mixin vertical-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}