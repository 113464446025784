section#about {
    @include vertical-alignment;
    height: 100vh;
    background-color: $alt-color;
    padding: 1em;
    border-bottom: 3px solid $bar-background;
    text-align: center;
    @include center-as-column;

    span,
    p {
        opacity: 0.8;
    }

    p {
        line-height: 1.25;
    }

    header {
        @include center-as-column;

        .profile-img {
            width: 5.5em;
            height: 5.5em;
            background: $background-color;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            margin: 0 0 1.2em;

            span {
                font-size: 2em;
            }
        }

        h2 {
            line-height: 0.8;
            margin: 1.3em 0 0;
        }

        h1 {
            line-height: 0.2;
        }

        @for $i from 1 through 6 {
            h#{$i} {
                color: $font-color;
            }
        }
    }

    #contact {
        header {
            margin-bottom: 1em;
            padding: 0;

            h2 {
                margin-top: 0.5em;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                a {
                    transition: opacity 0.6s;
                }

                a:hover {
                    opacity: 0.6;
                }
            }
        }

        svg {
            width: 2.3em;
        }
    }
}