$transition-time: 0.6s;
#skills {
    position: relative;
    #dev-skills {
        ul {
            display: flex;
            width: 100%;
            list-style: none;
            padding: 0;
        }
        ul,
        li {
            padding: 0.3em 0;
            margin: 0 0.7em 0 0;
        }
        li {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            .icon {
                svg {
                    width: 1.9em;
                    fill: $alt-color;
                    transition: fill $transition-time, width $transition-time;
                }
            }
           
            &:hover, &:focus {
                .icon {
                    svg {
                        fill: $color-hover;
                    }
                }               
            }
        }

        
    }
}